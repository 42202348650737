$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}



























































































































































.menu-mobile {
  &__container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: $modal-bg-color;
    z-index: $z-index-navigation;
    animation: reveallight 300ms;
  }

  &__content {
    @media (max-width: $breakpoint-small) {
      width: 100%;
    }
    position: absolute;
    top: 0;
    width: 90%;
    min-height: 100%;
    z-index: $z-index-navigation;
    padding: 5rem 1rem;
    background-color: $bg-color;
    border-radius: 0 $border-radius $border-radius 0;
    animation: revealSlide 300ms;

    &-notification {
      color: $main-dark-color;
      font-weight: 600;
      position: absolute;
      right: 0;
      margin-right: 1rem;
    }
  }

  &__link,
  &__content a {
    display: flex;
    align-items: center;
    border-bottom: solid $border-color 1px;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    min-width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &__content a,
  &__link {
    text-transform: capitalize;
  }

  &__close {
    font-size: $fs-text;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
  }

  &__visit {
    height: 12.5rem;
    margin: auto;
    text-align: center;
    margin-bottom: 1rem;
  }

  @media (min-width: $breakpoint-large) {
    display: none;
  }
}

.menu-icon {
  height: auto;
  width: 1.375rem;
  margin-right: 1rem;
  filter: invert(0.8);
  image-rendering: optimizequality;
  image-rendering: -webkit-optimize-contrast;
}

.nuxt-link-active {
  color: var(--second-option-color);
  font-weight: 600;
}
