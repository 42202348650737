$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}














































































.menu-mobile-landing {
  &__container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: $z-index-navigation;
    animation: reveallight 300ms;
  }

  &__content {
    @media (max-width: $breakpoint-small) {
      width: 100%;
    }
    position: absolute;
    top: 0;
    width: 90%;
    min-height: 100%;
    z-index: $z-index-navigation;
    padding: 5rem 1rem;
    background-color: $main-light-color;
    border-radius: 0 $border-radius $border-radius 0;
    font-size: $fs-title;
    animation: revealSlide 300ms;
  }

  &__close {
    font-size: $fs-text;
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
  }
  &__name,
  &__language,
  &__logout,
  &__login,
  &__link {
    font-size: $fs-text;
    border-bottom: solid $bg-color 1px;
    margin-bottom: 2rem;
    display: inline-block;
    min-width: 100%;
    transition: text-decoration 200ms;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__name {
    margin-bottom: 0;

    &:hover {
      text-decoration: none;
      cursor: default;
    }
  }

  @media (min-width: $breakpoint-large) {
    display: none;
  }
}
