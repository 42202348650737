$main-light-color: #FFFFFF;
$main-dark-color: #242020;
$main-medium-color: #5c5757;
$main-color: #242020;
$secondary-color: #746649;
$transparent-light-color: #ffffff63;
$transparent-medium-color: #ffffffcc;
$important-color: #e64591;

$valid-color: #417F01;
$alert-color: #d82222;
$bg-color: #F1F3F4;
$btn-main-color: #ffffffcc;
$msg-color: rgb(122, 228, 122);

$border-color: #E0E0E0;
$border-radius: 0.2rem;

$modal-bg-color: #00000071;

$fs-main-title: 1.50rem;
$fs-title: 1.375rem;
$fs-subTitle: 1.125rem;
$fs-text: 1rem;
$fs-tab: 1rem;
$fs-btn: 0.9rem;
$fs-link: 0.95rem;
$fs-small: 0.9rem;

$breakpoint-xs: 425px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-Xlarge: 1900px;

$breakpoint-h-small: 639px;

$z-index-navigation: 10;
$z-index-modal: 20;
$z-index-powered: 1010;

$header-size: 94px;
%ext-title {
  font-size: $fs-title;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 1rem;
}

%ext-subTitle {
  font-size: $fs-subTitle;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--second-option-color);
  padding-bottom: 1rem;
}

%ext-text {
  font-size: $fs-text;
  color: $main-medium-color;
  padding-bottom: 1rem;
}

%ext-tab {
  font-size: $fs-text;
  color: $main-medium-color;
  margin-bottom: 1rem;
  padding-bottom: 2px;
}

































































































.info-index {
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: $breakpoint-small) {
    display: flex;
    margin: auto;
  }
  @media (min-width: $breakpoint-large) {
    width: 84%;
    height: 84%;
    margin: auto;
  }
}

.info-form,
.demonstration {
  height: 50%;
  width: auto;

  @media (min-width: $breakpoint-small) {
    height: 100%;
    min-height: 100%;
    width: 50%;
  }
}

.info-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $main-light-color;

  &__btn {
    width: 280px;
    margin: 1.2rem auto;
    animation: revealSlidelight 500ms;
  }
}

.demonstration-header,
.demonstration-btn {
  animation: revealSlidelight 500ms;
}

.demonstration {
  &-header__description {
    @media (max-width: $breakpoint-small) {
      display: none;
    }
  }

  background-color: #595858ec;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__img {
    max-height: 29%;
    text-align: center;
    object-fit: contain;
    margin: auto;
  }

  &-btn {
    background-color: var(--second-option-color);
    color: $main-light-color;
    font-size: $fs-small;
    font-weight: 700;
    border-radius: $border-radius;
    text-align: center;
    text-transform: uppercase;
    transition: background-color linear 100ms;
    margin: 2rem auto;
    padding: 0.5rem 1rem;

    @media (min-width: $breakpoint-small) {
      padding: 0.2rem;
      border: solid $main-light-color 0.5rem;
      width: 30vmin;
      height: 30vmin;
      min-height: 20vmin;
      min-width: 20vmin;
      border-radius: 50%;
    }

    &:hover {
      background-color: var(--main-option-color);
      color: $main-light-color;
    }
  }

  &-header {
    background-color: var(--main-option-color);
    width: 90%;
    margin: auto;
    text-align: center;

    &__title {
      color: $main-light-color;
      padding: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      font-size: $fs-small;
    }

    &__subtitle {
      font-size: $fs-small;
    }

    &__description {
      text-align: left;
      padding: 1rem;
      background-color: $main-light-color;

      li,
      a {
        font-size: $fs-small;
      }
    }
  }
}

.header-link {
  font-size: $fs-link;
  text-decoration: underline;
  cursor: pointer;
}
